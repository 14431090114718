//* =================== standard banner =============
.banner__container {
  margin: 0 26.09%;
}

.banner__link {
  gap: 42px;
  & > .bx {
    font-size: 2em;
  }
}
@media screen and (max-width: 1919px) {
  .banner__container {
    margin: 0 18.21%;
  }
}

@media screen and (max-width: 1399px) {
  .banner__container {
    margin: 0 14.45%;
  }
  .banner__link {
    gap: 28.46px;
    & > .bx {
      font-size: 2em;
    }
  }
}

@media screen and (max-width: 767px) {
  .banner__container {
    margin: 0 4.87%;
  }
  .banner__title {
    font-size: 27.92px;
  }
  .banner__link {
    gap: 29.16px;
  }
}

@media screen and (max-width: 573px) {
  .banner__container {
    margin: 0 11.22%;
  }

  .banner__link {
    gap: 21.49px;
  }
}
